@import url(https://fonts.googleapis.com/css?family=Noto+Sans+HK:300,400,500,700&subset=chinese-hongkong&display=swap);
/* initialization style  */
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }
body, button, input, select, textarea { font:12px/1.5tahoma, arial, \5b8b\4f53; }
h1, h2, h3, h4, h5, h6{ font-size:100%; }
address, cite, dfn, em, var { font-style:normal; }
code, kbd, pre, samp { font-family:couriernew, courier, monospace; }
small{ font-size:12px; }
ul, ol { list-style:none; }
a { text-decoration:none; }
a:hover { text-decoration:underline; }
sup { vertical-align:text-top; }
sub{ vertical-align:text-bottom; }
legend { color:#000; }
fieldset, img { border:0; }
button, input, select, textarea { font-size:100%; }
table { border-collapse:collapse; border-spacing:0; }

html, body {
  /* font-size: calc((75 / (750 / 2)) * 100vw) !important;  */
  max-width: 100vw;
  color: #424242;
  line-height: 1;
  touch-action: manipulation;
  height: 100%;
  background-color: #fff !important;
  overflow-x: hidden;
}

.ant-select-item-option-content {
  overflow: auto !important;
  white-space: normal !important;
}
/* @import './assets/fonts/fonts.css'; */

[ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node, html {
  --antd-wave-shadow-color: transparent !important;
}

.spin-customer-loading {
  position: absolute;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  justify-content: center;
  align-items: center;
}

.am-toast-notice-content .am-toast-text {
  word-wrap:break-word;
  word-break:normal;
}

/*
Font Usage:
- woff2: Super Modern Browsers
- woff: Pretty Modern Browsers
- ttf: Safari, Android, iOS
*/



body {
  font-family: 'GT Walsheim';
}

