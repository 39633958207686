/*
Font Usage:
- woff2: Super Modern Browsers
- woff: Pretty Modern Browsers
- ttf: Safari, Android, iOS
*/

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+HK:300,400,500,700&subset=chinese-hongkong&display=swap');



body {
  font-family: 'GT Walsheim';
}
